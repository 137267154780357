@tailwind base;
@layer base {
    /*
    You can extend base styles here. Most are reset through Preflight. More info here: https://tailwindcss.com/docs/preflight 

    h1 {
        @apply text-2xl;
    }
    h2 {
        @apply text-xl;
    }
    h3 {
        @apply text-lg;
    }
    a {
        @apply text-blue-600 underline;
    } */
}
@layer components {
    /* Leaflet */
    .leaflet-wrapper {
        position: relative;

        .leaflet-container {
            width: 100%;
            height: calc(100vh - 240px);
            z-index: 1;
        }

        .leaflet-touch .leaflet-bar a {
            width: 32px;
            height: 32px;
            background-color: black;
            color: white;
            border-radius: 8px;
            border: none;
        }

        .leaflet-control-zoom {
            display: flex;
            gap: 8px;
            margin-top: 20px;
            margin-right: 0;
            border: none;

            @media screen and (min-width: 480px) {
                flex-direction: column;
            }
        }

        .leaflet-control-zoom-in {
            order: 1;

            @media screen and (min-width: 480px) {
                order: 0;
            }
        }

        .leaflet-right {
            right: 64px;

            @media screen and (min-width: 480px) {
                top: auto;
                bottom: 20px;
                right: 20px;
            }
        }

        .leaflet-bottom {
            display: none;
        }

        .marker-svg {
            &::before {
                content: '';
                width: 12px;
                height: 12px;
                position: absolute;
                top: 6px;
                left: 6px;
                border-radius: 24px;
                background-color: var(--color-icon-4);
            }

            &.martial-arts::before {
                background-size: 12px 12px;
                mask-image: url('/icons/sports/kickboxing.svg');
                mask-size: 12px 12px;
            }

            &.football::before {
                background-size: 12px 12px;
                mask-image: url('/icons/sports/football.svg');
                mask-size: 12px 12px;
            }

            &.gymnastics::before {
                background-size: 12px 12px;
                mask-image: url('/icons/sports/gymnastics.svg');
                mask-size: 12px 12px;
            }

            &.gym::before {
                background-size: 12px 12px;
                mask-image: url('/icons/sports/gym.svg');
                mask-size: 12px 12px;
            }

            &.swimming::before {
                background-size: 12px 12px;
                mask-image: url('/icons/sports/swimming.svg');
                mask-size: 12px 12px;
            }

            &.golf::before {
                background-size: 12px 12px;
                mask-image: url('/icons/sports/golf.svg');
                mask-size: 12px 12px;
            }

            &.basketball::before {
                background-size: 12px 12px;
                mask-image: url('/icons/sports/basketball.svg');
                mask-size: 12px 12px;
            }

            &.tabletennis::before {
                background-size: 12px 12px;
                mask-image: url('/icons/sports/tabletennis.svg');
                mask-size: 12px 12px;
            }

            &.volleyball::before {
                background-size: 12px 12px;
                mask-image: url('/icons/sports/volleyball.svg');
                mask-size: 12px 12px;
            }

            &.tennis::before {
                background-size: 12px 12px;
                mask-image: url('/icons/sports/tennis.svg');
                mask-size: 12px 12px;
            }

            &.airsoft::before {
                background-size: 12px 12px;
                mask-image: url('/icons/sports/airsoft.svg');
                mask-size: 12px 12px;
            }

            &.paintball::before {
                background-size: 12px 12px;
                mask-image: url('/icons/sports/paintball.svg');
                mask-size: 12px 12px;
            }

            &.squash::before {
                background-size: 12px 12px;
                mask-image: url('/icons/sports/squash.svg');
                mask-size: 12px 12px;
            }

            &.location::before {
                background-size: 12px 12px;
                mask-image: url('/icons/sports/location.svg');
                mask-size: 12px 12px;
            }
        }

        .marker-icon {
            position: relative;
            width: 24px;
            height: 24px;
            background-color: white;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            & > div {
                height: 100%;
                position: absolute;
                left: calc(100% + 2px);
                top: 0;
                background-color: white;
                width: max-content;
                padding: 4px 8px;
                border-radius: 10px;
                font-size: 12px;
                line-height: 16px;
                font-family: var(--font-family-primaryBold);
                color: var(--color-text-2);
            }
        }

        .marker-blank {
            & > div {
                display: none;
            }
        }

        .marker-icon--playoff {
            background-color: var(--color-background-5);

            &::before {
                background-color: var(--color-general-primary);
            }

            & > div {
                color: var(--color-text-6);
                background-color: var(--color-background-5);
            }
        }

        .marker-cluster-small,
        .marker-cluster-small div,
        .marker-cluster-small div .marker-cluster-large div,
        .marker-cluster-large,
        .marker-cluster-medium div,
        .marker-cluster-medium {
            background-color: black;
        }

        .marker-cluster {
            position: relative;
            width: 40px !important;
            height: 25px !important;
            padding: 4px 8px;
            border-radius: 36px;

            ::before {
                position: absolute;
                content: '';
                width: 8px;
                height: 8px;
                bottom: -4px;
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
                background-color: black;
            }
        }

        .marker-cluster div {
            height: 100%;
            width: 100%;
            color: white;
            text-align: center;
            font-size: 16px;
            line-height: 16px;
            font-weight: 700;
            text-transform: uppercase;
        }

        .leaflet-marker-icon {
            &:has(a > div:hover) {
                z-index: 9999 !important;
            }

            :hover {
                z-index: 9999 !important;
            }
        }

        .leaflet-cluster-anim .leaflet-marker-icon,
        .leaflet-cluster-anim .leaflet-marker-shadow {
            -webkit-transition: -webkit-transform 0.3s ease-out,
                opacity 0.3s ease-in;
            -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
            -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
            transition: transform 0.3s ease-out, opacity 0.3s ease-in;
        }

        .leaflet-cluster-spider-leg {
            /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
            -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out,
                -webkit-stroke-opacity 0.3s ease-in;
            -moz-transition: -moz-stroke-dashoffset 0.3s ease-out,
                -moz-stroke-opacity 0.3s ease-in;
            -o-transition: -o-stroke-dashoffset 0.3s ease-out,
                -o-stroke-opacity 0.3s ease-in;
            transition: stroke-dashoffset 0.3s ease-out,
                stroke-opacity 0.3s ease-in;
        }
    }

    /* React-slick */
    .slick-container {
        .slick-arrow::before {
            display: none;
        }

        .slick-dots {
            bottom: -60px;

            li {
                width: 20px;
                height: 20px;
                margin: 0;

                button {
                    width: 20px;
                    height: 20px;
                    padding: 4px;

                    &::before {
                        width: 12px;
                        height: 12px;
                        background: #61645e;
                        border-radius: 50%;
                        content: '';
                        left: 4px;
                        top: 4px;
                    }
                }
            }

            li.slick-active button:before {
                background: #acef34;
            }
        }
    }

    .scrollbar-thin {
        scrollbar-width: thin;
        scrollbar-color: var(--scrollbar-thumb, #4f4d56)
            var(--scrollbar-track, #2a282f);
        --scrollbar-thumb: theme('colors.background.4');
        --scrollbar-thumb-hover: theme('colors.background.5');
        --scrollbar-thumb-active: theme('colors.background.5');
        --scrollbar-thumb-radius: 4px;
        --scrollbar-track: theme('colors.background.2');
        --scrollbar-track-hover: theme('colors.background.3');
        --scrollbar-track-active: theme('colors.background.3');
        --scrollbar-track-radius: 4px;
        --scrollbar-corner: theme('colors.background.2');
        --scrollbar-corner-hover: theme('colors.background.3');
        --scrollbar-corner-active: theme('colors.background.3');
        --scrollbar-corner-radius: 4px;
    }

    .scrollbar-thin::-webkit-scrollbar {
        display: block;
        width: 8px;
        height: 8px;
    }

    .scrollbar-thin::-webkit-scrollbar-track {
        background-color: var(--scrollbar-track);
        border-radius: var(--scrollbar-track-radius);
    }

    .scrollbar-thin::-webkit-scrollbar-track:hover {
        background-color: var(--scrollbar-track-hover, var(--scrollbar-track));
    }

    .scrollbar-thin::-webkit-scrollbar-track:active {
        background-color: var(
            --scrollbar-track-active,
            var(--scrollbar-track-hover, var(--scrollbar-track))
        );
    }

    .scrollbar-thin::-webkit-scrollbar-thumb {
        background-color: var(--scrollbar-thumb);
        border-radius: var(--scrollbar-thumb-radius);
    }

    .scrollbar-thin::-webkit-scrollbar-thumb:hover {
        background-color: var(--scrollbar-thumb-hover, var(--scrollbar-thumb));
    }

    .scrollbar-thin::-webkit-scrollbar-thumb:active {
        background-color: var(
            --scrollbar-thumb-active,
            var(--scrollbar-thumb-hover, var(--scrollbar-thumb))
        );
    }

    .scrollbar-thin::-webkit-scrollbar-corner {
        background-color: var(--scrollbar-corner);
        border-radius: var(--scrollbar-corner-radius);
    }

    .scrollbar-thin::-webkit-scrollbar-corner:hover {
        background-color: var(
            --scrollbar-corner-hover,
            var(--scrollbar-corner)
        );
    }

    .scrollbar-thin::-webkit-scrollbar-corner:active {
        background-color: var(
            --scrollbar-corner-active,
            var(--scrollbar-corner-hover, var(--scrollbar-corner))
        );
    }

    .overflow-clip-path {
        clip-path: inset(0 0 -10px 0);
    }

    input[type='time']::-webkit-calendar-picker-indicator {
        filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
            brightness(95%) contrast(80%);
        cursor: pointer;
    }

    input[type='text'] {
        &:focus {
            border-color: none;
            box-shadow: none;
        }
    }
}
@tailwind components;
@tailwind utilities;
